import { ThemeProvider } from "@material-ui/core/styles";
import { useEffect, useState, lazy, Suspense } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import useTheme from "./hooks/useTheme";
import { light as lightTheme } from "./themes/light.js";

import "./style.scss";
import LoadingSplash from './components/LoadingSplash'
import Messages from "./components/Messages/Messages";

const DEBUG = false;
const Home = lazy(() => import('./views/home'));
const Terms = lazy(() => import('./views/terms'));
const PrivacyPolicy = lazy(() => import('./views/privacyPolicy'));
if (DEBUG) console.log(" Connecting to Mainnet Ethereum");

import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./components/Header";
AOS.init({
  duration: 500,
  easing: 'ease-out-back',
  delay: 0
})

// const blockExplorer = targetNetwork.blockExplorer;

function App() {
  const [theme, mounted] = useTheme();
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname)

  useEffect(() => {
    setPathname(location.pathname)
  }, [location]);

  let themeMode = lightTheme;

  return (
    <ThemeProvider theme={themeMode}>
      <CssBaseline />
      <Messages />
      <Suspense fallback={<LoadingSplash />}>
        <div className={`app-container`}>
          {
            pathname != '/' &&
            <Header />
          }
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/PrivacyPolicy">
              <PrivacyPolicy />
            </Route>
            <Route exact path="/Terms">
              <Terms />
            </Route>
            <Route exact path="/">
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
