import { Box, Link, useMediaQuery } from '@material-ui/core';

import './header.scss';
import Logo1 from '../../assets/images/logo.svg';
import Twitter1 from '../../assets/images/header/twitter.svg';
import Discord1 from '../../assets/images/header/discord.svg';
import Telegram1 from '../../assets/images/header/telegram.svg';
import Github1 from '../../assets/images/header/github.svg';
import { NavLink } from 'react-router-dom';

export default function Header() {

    const isSmallScreen = useMediaQuery("(max-width: 1080px)")

    return (
        <div className="header-view">
            <Box display='flex' alignItems='center' justifyContent='space-between' className='header font-BlenderPro-Bold'>
                <Box display='flex' alignItems='center' className="header-l">
                    <a target={'_self'} href='/' className="header-logo">
                        <img src={Logo1} />
                    </a>
                </Box>
                <Box display='flex' alignItems='center' className='header-r color3 font-14'>
                    {
                        !isSmallScreen &&
                        <Box display='flex' alignItems='center'>
                            <a href="https://twitter.com/GuruAkashic" target="_blank">
                                <img src={Twitter1} />
                            </a>
                            <a href="https://discord.gg/KKKGaD7Zwn" target="_blank">
                                <img src={Discord1} className="ml-4" />
                            </a>
                            <a href="https://t.me/akashicguru" target="_blank">
                                <img src={Telegram1} className="ml-4" />
                            </a>
                            <a href="https://docs.akashic.guru/" target="_blank">
                                <img src={Github1} className="ml-4" />
                            </a>
                        </Box>
                    }
                    <Box display='flex' alignItems='center' justifyContent='center' className="connect">Connect Wallet</Box>
                </Box>
            </Box>
        </div>
    )
}