import BlenderProHeavyWOFF from "../assets/fonts/BlenderPro-Heavy.woff";
import BlenderProBoldItalicWOFF from "../assets/fonts/BlenderPro-BoldItalic.woff";
import BlenderProBoldWOFF from "../assets/fonts/BlenderPro-Bold.woff";
import BlenderProBookWOFF from "../assets/fonts/BlenderPro-Book.woff";
import BlenderProMediumWOFF from "../assets/fonts/BlenderPro-Medium.woff";

const blenderProHeavy = {
  fontFamily: "BlenderPro-Heavy",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('BlenderProHeavy'),
		local('BlenderPro-Heavy'),
		url(${BlenderProHeavyWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const blenderProBoldItalic = {
  fontFamily: "BlenderPro-BoldItalic",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('BlenderProBoldItalic'),
		local('BlenderPro-BoldItalic'),
		url(${BlenderProBoldItalicWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const blenderProBold = {
  fontFamily: "BlenderPro-Bold",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('BlenderProBold'),
		local('BlenderPro-Bold'),
		url(${BlenderProBoldWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const blenderProBook = {
  fontFamily: "BlenderPro-Book",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('BlenderProBook'),
		local('BlenderPro-Book'),
		url(${BlenderProBookWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const blenderProMedium = {
  fontFamily: "BlenderPro-Medium",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('BlenderProMedium'),
		local('BlenderPro-Medium'),
		url(${BlenderProMediumWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const fonts = [blenderProHeavy,blenderProBoldItalic,blenderProBold,blenderProBook,blenderProMedium];

export default fonts;
